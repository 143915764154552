import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contacts from "../components/contacts";

// Patterns
import PatternFirst from "../images/pattern_01.png";

export default function Home({
  data: { page, gmap, contacts, forms, header, footer, socials, copyright, user, cookies },
}) {
  const wpContent = page.nodes[0];
  forms = forms.nodes[0]
  contacts = contacts.nodes[0]
  
  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={forms}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.nodes[0].locale.locale}
      uri={page.nodes[0].uri}
    >
      <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
      <header className="head">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          <ol className="breadcrumb__list">
            {/* {wpContent.seo.breadcrumbs.map((value, i) => (
              <li key={i} className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={`/ru${value.url}`}
                  dangerouslySetInnerHTML={{
                    __html: value.text,
                  }}
                ></Link>
              </li>
            ))} */}
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to="/ru/"
              >Главная</Link>
            </li>
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to={`/ru${wpContent.seo.breadcrumbs[1].url}`}
                dangerouslySetInnerHTML={{
                  __html: wpContent.seo.breadcrumbs[1].text,
                }}
              ></Link>
            </li>
          </ol>
        </nav>
        <h1>{wpContent.pageContacts.pageTitle}</h1>
        <p>{wpContent.pageContacts.pageSubtitle}</p>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </header>
      <Contacts socials={socials.nodes[0].socialNetworks} forms={forms} contacts={contacts} />
    </Layout>
  );
}

export const query = graphql`
  {
    copyright: allWpRepeatableBlocks(
      filter: {slug: {eq: "copyrights"}}
    ) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(filter: {slug: {eq: "ugoda-korystuvacha"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(filter: {slug: {eq: "polityka-cookies"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    page: allWpPage(
      filter: { locale: { locale: { eq: "ru" } }, slug: { eq: "contacts" } }
    ) {
      nodes {
        pageContacts {
          pageSubtitle
          pageTitle
        }
        uri
        slug
        title
        seo {
          title
          metaDesc
          breadcrumbs {
            text
            url
          }
        }
        locale {
          locale
        }
      }
    }
    gmap: allWpRepeatableBlocks(
      filter: { slug: { eq: "google-karta" }, locale: { locale: { eq: "ru" } } }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockGoogleMap {
          mapCoords {
            lat
            lng
          }
        }
      }
    }
    contacts: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "ru" } }
        slug: { eq: "kontaktna-informacziya" }
      }
    ) {
      nodes {
        slug
        title
        blockContacts {
          contactAddressText
          contactAddressTitle
          contactEmailAdressText
          contactEmailAdressTitle
          contactPhonesListTitle
          fieldGroupName
          scheduleListTitle
          contactPhonesList {
            phoneNumber
          }
          scheduleList {
            item
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "ru" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        slug
        title
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          fieldGroupName
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
        locale {
          locale
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "ru" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
